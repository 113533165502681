@font-face {
  font-family: "GothRegular";
  src: url("./fonts/GothicA1-Regular.ttf");
}
@font-face {
  font-family: "GothBold";
  src: url("./fonts/GothicA1-Bold.ttf");
}
@font-face {
  font-family: "GothExtraBold";
  src: url("./fonts/GothicA1-ExtraBold.ttf");
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputForm {
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
}
